

const ClientOrganization = () => import("@/components/ClientOrganization.vue")

import Vue from "vue"
import { GetInvitesItem } from "@/types/responses"
export default Vue.extend({
	components: { 
        ClientOrganization
    },
	name: "CustomerClientsDialog",
	props: {
		clientDialog: {
			type: Boolean,
		},
		item: Object as () => GetInvitesItem,
	},
	methods: {
		closeDialog(isLastMember?: boolean): void {

            if(isLastMember){
                this.$emit('close', true)
            }else {
            
                this.$emit('close', null)
            }
		},
	},
})
